<template>
    <div id="dealersearch" class="section-row" ref="appcontainer">
        <div class="col-wrapper -max-width-limited">
            <div class="col col-one" ref="content">
                <div class="module-breadcrumb">
                    <div class="col-wrapper">
                        <div class="col">
                            <div class="module-nav module-nav--breadcrumb">
                                <div class="swiper-container">
                                    <div class="swiper-wrapper" v-html="breadcrumb">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-item module-dealersearch">
                    <div class="dealersearch__headline">
                        <h1 class="text-left beta heading">{{ $t("dealerlist.title") }}</h1>
                    </div>
                    <div class="dealersearch__form form">
                        <div class="form__search">
                            <div class="form-group">
                                <div class="input icon-search-after search-icon-dese">
                                    <input type="text" id="location" :class="{ '-filled': location }" name="location" :placeholder="$t('dealerlist.placeholder')" v-model="location" ref="autocomplete">
                                    <div class="input-background">
                                        <label class="control-label" for="location">{{ $t("dealerlist.placeholder") }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dealers-filter_header"><div class="text-left epsilon heading">{{$t("filter.filterText")}}</div></div>
                  <div class="dealersearch-filter">
                    <div v-show="showError" class="-error-message">
                      {{ $t("filter.error") }}
                    </div>
                    <div class="button__wrapper">
                            <button id="segelyachtFilter" @click="byTypeFilter" data-type="sy" class="-btn-filter" :class="location.length ? '' : '-disabled'"  type="submit" >
                                <span>
                                    <svg width="14" height="13">
                                      <path fill="#003863" fill-rule="nonzero" d="M.898 9.533c-.257-3.102 1.1-5.58 3.329-6.066L5.2 8.954c-1.05-.092-3.33.209-4.302.58zM4.333.026c3.38-.344 6.606 2.798 7.8 7.61-1.448-.148-5.056.442-6.377 1.03L4.333.027zM11.267 13H.892L0 10.938l13.867-2.271-2.6 4.333z"/>
                                    </svg>
                                </span>
                                {{ $t("filter.sailingyachts") }}
                            </button>

                            <button id="motorbootFilter"  @click="byTypeFilter" data-type="mb" class="-btn-filter" :class="location.length ? '' : '-disabled'"   type="submit">
                                <span>
                                    <svg width="17" height="8">
                                      <path fill="#003863" fill-rule="nonzero" d="M14.192 8H0l1.29-1V5L17 4l-2.808 4zM14 3L3.837 4l.766-2.857H3L3.437 0h4.801c2.646.667 3.606 1 2.881 1-1.92 0-3.2.333-3.84 1h5.393L14 3z"/>
                                    </svg>
                                </span>
                                {{ $t("filter.motorboats") }}
                            </button>
                        </div>
                        <button class="-btn-filter-link" type="submit" @click="showAll">
                            {{ $t("filter.worldwide") }}
                        </button>
                    </div>
                    <div class="dealers-list">
                        <ul class="country__dealers">
                            <li class="dealers__dealer"
                                v-for="(dealer, index) in filteredcountry"
                                :class="[ index === activeDealer ? 'dealers__dealer--active' : '' ]"
                                :key="index + '-' + dealer.zip"
                                :data-index="index"
                                @mouseover="activeDealer = index">
                                <a :href="detailsite + dealer.slug">
                                  <div class="dealer-item" :class="[ index === activeDealer ? 'dealers__dealer--active' : '' ]">
                                    <div class="name-address">
                                      <span class="epsilon heading">{{dealer.title}}</span><br>
                                      <span v-if="dealer.country"> {{ dealer.zip }} {{ dealer.city }}, {{ dealer.country }}</span>
                                      <span v-else> {{ dealer.zip }} {{ dealer.city }}, {{ dealer.country }}</span>
                                    </div>
                                    <div class="types">

                                            <span v-show="dealer.segelyacht"
                                                  class="sy"
                                                  :class="[ segelyachtFilter === 1 ? 'active' : '' ]"
                                                  title="Segelyacht"><svg width="26" height="26">
                                                      <g fill="#003863" fill-rule="nonzero">
                                                        <path d="M13 26c7.18 0 13-5.82 13-13S20.18 0 13 0 0 5.82 0 13s5.82 13 13 13zm0-1C6.373 25 1 19.627 1 13S6.373 1 13 1s12 5.373 12 12-5.373 12-12 12z"/>
                                                        <path d="M6.898 14.533c-.257-3.102 1.1-5.58 3.329-6.066l.973 5.487c-1.05-.092-3.33.209-4.302.58zm3.435-9.507c3.38-.344 6.606 2.798 7.8 7.61-1.448-.148-5.056.442-6.377 1.03l-1.423-8.64zM17.267 18H6.892L6 15.938l13.867-2.271-2.6 4.333z"/>
                                                      </g>
                                                    </svg></span>

                                      <span v-show="dealer.motorboot"
                                            class=" mb"
                                            :class="[ motorbootFilter === 1 ? 'active' : '' ]"
                                            title="motorboot"><svg width="26" height="26">
                                                  <g fill="#003863" fill-rule="nonzero">
                                                    <path d="M13 26c7.18 0 13-5.82 13-13S20.18 0 13 0 0 5.82 0 13s5.82 13 13 13zm0-1C6.373 25 1 19.627 1 13S6.373 1 13 1s12 5.373 12 12-5.373 12-12 12z"/>
                                                    <path d="M19.192 17H5l1.29-1v-2L22 13l-2.808 4zM19 12L8.837 13l.766-2.857H8L8.437 9h4.801c2.646.667 3.606 1 2.881 1-1.92 0-3.2.333-3.84 1h5.393L19 12z"/>
                                                  </g>
                                                </svg>  </span>
                                    </div>
                                    <div class="dealer-details-link">
                                      <svg width="12" height="10">
                                        <path fill="#003863" fill-rule="evenodd" d="M7.54 9.705l4.135-3.967.014-.011c.149-.143.242-.319.284-.503l.005-.018c.014-.067.022-.135.022-.203v-.001a.986.986 0 0 0-.022-.204c0-.006-.004-.012-.005-.018a1.001 1.001 0 0 0-.284-.503l-.014-.01L7.54.298a1.102 1.102 0 0 0-1.508 0 .997.997 0 0 0 0 1.447l2.326 2.232H1.067C.48 3.978 0 4.438 0 5.002c0 .563.48 1.023 1.067 1.023h7.291L6.032 8.258a.997.997 0 0 0 0 1.447 1.102 1.102 0 0 0 1.508 0"/>
                                      </svg>
                                    </div>
                                  </div>
                                </a>
                            </li>
                        </ul>
                      <div v-if="dealers.length" class="dealers_divider"><div class="text-left gamma heading">{{$t("filter.othersText")}}</div></div>
                      <ul>
                        <li class="dealers__dealer"
                            v-for="(dealer, index) in filteredGlobal"
                            :class="[ index + filteredcountry.length === activeDealer ? 'dealers__dealer--active' : '' ]"
                            :key="index  + '-' + dealer.zip"
                            :data-index="index + filteredcountry.length"
                            @mouseover="activeDealer = index + filteredcountry.length">
                          <a :href="detailsite + dealer.slug"
                             target="_blank">
                            <div class="dealer-item" :class="[ index + filteredcountry.length === activeDealer ? 'dealers__dealer--active' : '' ]">
                              <div class="name-address">
                                <span class="epsilon heading">{{dealer.title}}</span><br>
                                <span> {{ dealer.zip }} {{ dealer.city }}, {{ dealer.country }}</span>
                              </div>
                              <div class="types">

                                            <span v-show="dealer.segelyacht"
                                                  class="sy"
                                                  :class="[ segelyachtFilter === 1 ? 'active' : '' ]"
                                                  title="Segelyacht"><svg width="26" height="26">
                                                      <g fill="#003863" fill-rule="nonzero">
                                                        <path d="M13 26c7.18 0 13-5.82 13-13S20.18 0 13 0 0 5.82 0 13s5.82 13 13 13zm0-1C6.373 25 1 19.627 1 13S6.373 1 13 1s12 5.373 12 12-5.373 12-12 12z"/>
                                                        <path d="M6.898 14.533c-.257-3.102 1.1-5.58 3.329-6.066l.973 5.487c-1.05-.092-3.33.209-4.302.58zm3.435-9.507c3.38-.344 6.606 2.798 7.8 7.61-1.448-.148-5.056.442-6.377 1.03l-1.423-8.64zM17.267 18H6.892L6 15.938l13.867-2.271-2.6 4.333z"/>
                                                      </g>
                                                    </svg></span>

                                <span v-show="dealer.motorboot"
                                      class=" mb"
                                      :class="[ motorbootFilter === 1 ? 'active' : '' ]"
                                      title="motorboot"><svg width="26" height="26">
                                                  <g fill="#003863" fill-rule="nonzero">
                                                    <path d="M13 26c7.18 0 13-5.82 13-13S20.18 0 13 0 0 5.82 0 13s5.82 13 13 13zm0-1C6.373 25 1 19.627 1 13S6.373 1 13 1s12 5.373 12 12-5.373 12-12 12z"/>
                                                    <path d="M19.192 17H5l1.29-1v-2L22 13l-2.808 4zM19 12L8.837 13l.766-2.857H8L8.437 9h4.801c2.646.667 3.606 1 2.881 1-1.92 0-3.2.333-3.84 1h5.393L19 12z"/>
                                                  </g>
                                                </svg>  </span>
                              </div>
                              <div class="dealer-details-link">
                                <svg width="12" height="10">
                                  <path fill="#003863" fill-rule="evenodd" d="M7.54 9.705l4.135-3.967.014-.011c.149-.143.242-.319.284-.503l.005-.018c.014-.067.022-.135.022-.203v-.001a.986.986 0 0 0-.022-.204c0-.006-.004-.012-.005-.018a1.001 1.001 0 0 0-.284-.503l-.014-.01L7.54.298a1.102 1.102 0 0 0-1.508 0 .997.997 0 0 0 0 1.447l2.326 2.232H1.067C.48 3.978 0 4.438 0 5.002c0 .563.48 1.023 1.067 1.023h7.291L6.032 8.258a.997.997 0 0 0 0 1.447 1.102 1.102 0 0 0 1.508 0"/>
                                </svg>
                              </div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="more-dealer" v-if="dealers.length > dealersCount">
                        <p @click="addDealers" class="bold">{{ $t("dealerlist.moreButton") }}</p>
                    </div>
                </div>
            </div>
            <div class="col col-two dealer-map-container">
                <GoogleMap
                        v-if="isGoogle"
                        id="map"
                        :currentPlace="currentPlace"
                        :dealers="filteredcountry.concat(filteredGlobal)"
                        :activeDealer="activeDealer"
                        @set-content-height="$refs.content.style.minHeight = $event + 'px'"
                        @marker-selected="setActiveDealer($event)"
                        @get-dealers-by-center="setCurrentPlace($event, true)"/>
            </div>
        </div>
    </div>
</template>

<script>
    /* global google */

    import axios from 'axios';
    import GoogleMap from './components/GoogleMap';
    import $ from "jquery";

    export default {
        name: 'app',
        data() {
            return {
                detailsite: window.tx_dealersearch.dealersearch.detailsite,
                apiBase: window.tx_dealersearch.dealersearch.api,
                breadcrumb: window.tx_dealersearch.dealersearch.breadcrumb,
                newApi:'',
                dealers: [],
                page: 1,
                pageSize: 6,
                activeDealer: -1,
                location: '',
                currentPlace: {},
                segelyachtFilter: 0,
                motorbootFilter: 0,
                euBounds: {},
                dealerSearched: false,
                filteredDealerCountry: '',
                showError: false,
                isGoogle: false
            }
        },
        components: {GoogleMap},
        computed: {
            dealersCount: function () {
                // deactivate pagination
                // return this.pageSize * (this.page + 1)
                return this.dealers.length
            },
            filteredDealers: function () {
                return this.dealers.filter((dealer, index) => index < this.dealersCount)
            },
          filteredcountry: function () {
            return this.filteredDealers.filter((dealer) => this.filteredDealerCountry === dealer.countryIso2)
          },
          filteredGlobal: function () {
            return this.filteredDealers.filter((dealer) => this.filteredDealerCountry !== dealer.countryIso2)
          }
        },
        methods: {
            showAll: function() {
                axios.get(this.apiBase)
                    .then(response => {
                        this.page = 0;
                        this.pageSize = this.dealers.length;
                        this.dealers = response.data.dealers;
                        this.activeDealer = 0;
                        this.location = ' '
                    })
            },
            byTypeFilter: function(event){
              if(this.location !== ''){
                $(event.target).toggleClass('-btn-active')
                var icon = $(event.target).data('type')
                $('.'+icon).toggleClass('active')
                this[event.target.id] === 0 ? this[event.target.id]=1 : this[event.target.id]=0
                this.getDealers()
              }
              else{
                window.addEventListener("mousedown", event => {
                if(!$(event.target).hasClass('-error-message')) {
                  this.showError=false
                  window.removeEventListener("mousedown", {} )
                }

                })
                this.showError= true
              }

            },
            slugify: function (text) {
                return text.toString().toLowerCase()
                    .replace(/\s+/g, '-')           // Replace spaces with -
                    .replace(/[^\w-]+/g, '')       // Remove all non-word chars
                    .replace(/&#45;&#45;+/g, '-')         // Replace multiple - with single -
                    .replace(/^-+/, '')             // Trim - from start of text
                    .replace(/-+$/, '');            // Trim - from end of text
            },
            getDealers: function () {
                let url = new URL(this.apiBase)
                if (this.segelyachtFilter === 1) {
                    url.searchParams.set('segelyacht', this.segelyachtFilter)
                }
                if (this.motorbootFilter === 1) {
                    url.searchParams.set('motorboot', this.motorbootFilter)
                }
                if(this.currentPlace.latitude) {
                    url.searchParams.set('lat', this.currentPlace.latitude);
                    url.searchParams.set('lng', this.currentPlace.longitude);
                }
                this.newApi = this.apiBase;
                axios.get(url)
                    .then(response => {
                        this.page = 1;
                        this.pageSize = 6;
                        this.dealers = response.data.dealers;
                        this.activeDealer = 0;
                    })
            },
            addDealers: function () {
                this.page += 1;
                window.setTimeout(
                    function () {
                        let event = document.createEvent('Event');
                        event.initEvent('resize', true, true);
                        window.dispatchEvent(event);
                    },
                    100
                );
            },
            setCurrentPlace: function (map) {
                this.currentPlace = map.center;
                this.getDealersByGeocode();
            },
            getDealersByGeocode: function () {
                this.dealerSearched = true
                this.getDealers()
            },
            selectFirstOnKeydown: function (input) {
                /* Store original event listener */
                const _addEventListener = input.addEventListener

                const addEventListenerWrapper = (type, listener) => {
                    if (type === 'keydown') {
                        /* Store existing listener function */
                        const _listener = listener
                        listener = (event) => {
                            /* Simulate a 'down arrow' keypress if no address has been selected */
                            const suggestionSelected = document.getElementsByClassName('pac-item-selected').length > 0
                            if ((event.which === 9 || event.which === 13) && !suggestionSelected) {
                                const e = new CustomEvent(JSON.parse(JSON.stringify(event)))
                                e.which = 40
                                e.keyCode = 40
                                _listener.apply(input, [e])
                            }
                            _listener.apply(input, [event])
                        }
                    }
                    _addEventListener.apply(input, [type, listener])
                }

                input.addEventListener = addEventListenerWrapper
            },
            setActiveDealer: function (dealer) {
                this.activeDealer = dealer
                let scrollTo = document.querySelectorAll('.dealers__dealer[data-index="' + dealer + '"]')[0].offsetTop;
                let maxScroll = this.$refs.appcontainer.offsetTop + this.$refs.appcontainer.offsetHeight - window.innerHeight;
                scrollTo = scrollTo < maxScroll ? scrollTo : maxScroll

                window.setTimeout(function () {
                    $('html, body').animate({
                        scrollTop:scrollTo
                    },'slow');
                },150);
            },
          init: function () {
            this.euBounds = new google.maps.LatLngBounds()
            let euBounds = new google.maps.LatLngBounds(
                new google.maps.LatLng(36.3027639, -12.3010810),
                new google.maps.LatLng(70.9546922, 32.6989190)
            )
            this.euBounds = euBounds;
            this.autocomplete = new google.maps.places.Autocomplete(
                (this.$refs.autocomplete),
                {
                  //bounds: euBounds,
                  types: ['(regions)'],
                  fields: ['address_components', 'geometry', 'icon', 'name', 'formatted_address']
                }
            )

            this.autocomplete.addListener('place_changed', () => {
              let place = this.autocomplete.getPlace()
              if (place.geometry) {
                this.setCurrentPlace({
                  center: {
                    latitude: place.geometry.location.lat(),
                    longitude: place.geometry.location.lng()
                  }
                })

                this.location = place.formatted_address
                let country_component = place.address_components.filter((component) => component.types[0] === 'country')
                this.filteredDealerCountry = country_component[0].short_name
                // because United Kingdom has two iso-codes
                if (this.filteredDealerCountry === 'GB') {
                  this.filteredDealerCountry = 'UK'
                }
              }
            })


            this.selectFirstOnKeydown(this.$refs.autocomplete)
          }
        },
        mounted() {
          let interval = setInterval(() => {
            if (google && typeof google === 'object') {
              this.init()
              this.isGoogle = true
              clearInterval(interval)
            }
          }, 1000)
        },

    }
</script>

