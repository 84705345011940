<template>
  <div class="module-map__wrapper" :key="componentKey">
    <div class="module-map map-container" v-if="consentIsGiven()" style="position: relative; width: 100%; height: 100%;" ref="container">
      <div class="map-wrapper" ref="wrapper">
        <div :id="id" style="width: 100%; height: 100%;" :ref="id"></div>
        <div class="map__control" v-if="centerChanged">
          <div class="control__button">
            <div class="-btn-map" @click="getDealersByCenter">
              <span>{{ $t("googleApi.title") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="map-placeholder" v-if="!consentIsGiven()">
      <div class="consent__wrapper">
        <div class="consent__text">
          <span>{{ $t("consent.text1") }}</span>
          <a :href="privacy">{{ $t("consent.privacy") }}</a>
          <span>{{ $t("consent.text2") }}</span>
        </div>
        <div class="consent__button -btn-map" @click="setConsent">
          <span>{{ $t("consent.button") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    /* global google */
    import $ from "jquery";

    export default {
        data () {
            return {
                map: '',
                centerChanged: false,
                mapBounds: {},
                componentKey: 0,
                privacy: window.tx_dealersearch.dealersearch.privacy,
                currentSessionStorage: window.sessionStorage.googleMapsConsent,
                markerIconDealer: {
                    url: require('@/img/marker--inactive.svg'),
                    size: new google.maps.Size(47, 55),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(20, 55),
                    scaledSize: new google.maps.Size(47, 55)
                },
                markerIconDealerActive: {
                    url: require('@/img/marker--active.svg'),
                    size: new google.maps.Size(65, 76),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(27, 76),
                    scaledSize: new google.maps.Size(65, 76)
                },
                markerIconCenter: {
                    url: require('@/img/icon-map-marker-center.png'),
                    size: new google.maps.Size(38, 38),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(19, 19),
                    scaledSize: new google.maps.Size(38, 38)
                },
                markers: [],
                markerCenter: {}
            }
        },
        props: [ 'id', 'currentPlace', 'dealers', 'activeDealer' ],
        watch: {
            dealers: function (newDealers) {
                let self = this
                self.markers.forEach(function (marker) {
                    marker.setMap(null)
                })
                self.markers = []
                self.mapBounds = new google.maps.LatLngBounds()

                newDealers.forEach(function (dealer, index) {
                    self.addMapMarker(self, dealer, index)
                })

                if (self.activeDealer >= 0) {
                    let index = self.activeDealer
                    self.map.fitBounds(self.mapBounds)
                    self.map.panTo(self.markers[index].getPosition())
                }
                self.centerChanged = false
            },
            activeDealer: function (newActiveDealer, oldActiveDealer) {
                if (oldActiveDealer >= 0 && this.markers.length + 1 >= oldActiveDealer) {
                    this.markers[oldActiveDealer].setIcon(this.markerIconDealer)
                }
                if (newActiveDealer >= 0) {
                    this.markers[newActiveDealer].setIcon(this.markerIconDealerActive)
                    this.map.panTo(this.markers[newActiveDealer].getPosition());
                    //this.toggleBounce(this.markers[newActiveDealer]);
                }

            },
            currentPlace: function (newPlace, oldPlace) {
                if (newPlace.latitude && newPlace.longitude) {
                    let self = this
                    // remove old marker
                    if (oldPlace.latitude && oldPlace.longitude) {
                        self.markerCenter.setMap(null)
                    }
                    // add new marker
                    let LatLng = new google.maps.LatLng(newPlace.latitude, newPlace.longitude)
                    this.markerCenter = new google.maps.Marker({
                        position: LatLng,
                        // animation: google.maps.Animation.DROP,
                        icon: self.markerIconCenter,
                        zIndex: -999999999
                    })

                    self.mapBounds.extend(LatLng)
                    self.markerCenter.setMap(self.map)
                }
            },
        },
        mounted () {
          let interval = setInterval(() => {
            if (google && typeof google === 'object') {
              this.init()
              clearInterval(interval)
            }
          }, 500)
        },
        methods: {
          forceRerender: function () {
            this.componentKey += 1
          },
          setConsent: function () {
            window.sessionStorage.setItem('googleConsent', 'consentGiven')
            this.forceRerender()
            let interval = setInterval(() => {
              if (google && typeof google === 'object') {
                this.init()
                clearInterval(interval)
              }
            }, 500)
          },
          consentIsGiven: function () {
            if(window.sessionStorage.length > 0) {
              if(window.sessionStorage.googleConsent === 'consentGiven') {
                return true
              }
            }
            return false
          },
          init: function () {
            let self = this
            if(self.consentIsGiven()) {
              self.setMapDimensions(self)
              self.setMapPosition(self)


            window.addEventListener('resize', function () {
              self.setMapDimensions(self)
              self.setMapPosition(self)
            })

            window.addEventListener('scroll', function () {
              self.setMapPosition(self)
            })

            let index = 0
            let center = { lat: 51.133481, lng: 10.018343 }
            if (self.activeDealer >= 0) {
              index = self.activeDealer
              center = { lat: parseFloat(self.dealers[index].lat), lng: parseFloat(self.dealers[index].lng) }
            }
              self.map = new google.maps.Map(self.$refs[self.id], {
                center: center,
                scrollwheel: true,
                zoom: 5,
                minZoom: 1,
                maxZoom: 14,
                fullscreenControl: false
              })

              self.mapBounds = new google.maps.LatLngBounds();

              self.dealers.forEach(function (dealer, index) {
                self.addMapMarker(self, dealer, index)
              })

              if (self.activeDealer >= 0) {
                self.map.fitBounds(self.mapBounds)
                self.map.panTo(self.markers[index].getPosition())
              }

              window.setTimeout(() => {
                self.map.addListener('dragend', () => {
                  self.centerChanged = true
                })
                self.map.addListener('zoom_changed', () => {
                  self.centerChanged = true
                })
              }, 100)
            }
          },
            setMapDimensions: function (self) {
                self.$refs.wrapper.style.width = window.innerWidth - self.$refs.container.getBoundingClientRect().left + 'px'
                self.$refs.wrapper.style.height = window.innerHeight + 'px'
            },
            setMapPosition: function (self) {
                let containerRect = self.$refs.container.getBoundingClientRect()
                let dealersearchRect = document.getElementById('dealersearch').getBoundingClientRect()
                let triggerTop = containerRect.top - dealersearchRect.top
                let triggerBottom = dealersearchRect.top + dealersearchRect.height - self.$refs.wrapper.getBoundingClientRect().height
                self.$refs.wrapper.style.position = 'absolute'
                self.$refs.wrapper.style.top = triggerTop * (-1) + 'px'
                self.$refs.wrapper.style.bottom = 'auto'
                self.$refs.wrapper.style.left = '0px'
                if (containerRect.top < triggerTop && triggerBottom > 0) {
                    self.$refs.wrapper.style.position = 'fixed'
                    self.$refs.wrapper.style.top = '0px'
                    self.$refs.wrapper.style.bottom = 'auto'
                    self.$refs.wrapper.style.left = containerRect.left + 'px'
                }
                if (triggerBottom <= 0) {
                    self.$refs.wrapper.style.position = 'absolute'
                    self.$refs.wrapper.style.top = 'auto'
                    self.$refs.wrapper.style.bottom = triggerTop * (-1) + 'px'
                    self.$refs.wrapper.style.left = '0px'
                }
                if($(window).width() >= 1024) {
                    self.$emit('set-content-height', window.innerHeight - 2 * triggerTop)
                } else {
                    self.$emit('set-content-height', containerRect)

                }

                $(window).resize(function() {
                    if($(window).width() >= 1024) {
                        self.$emit('set-content-height', window.innerHeight - 2 * triggerTop)
                    } else {
                        self.$emit('set-content-height', containerRect)

                    }
                });
            },
            getDealersByCenter: function () {
                let boundsNE = this.map.getBounds().getNorthEast()
                let boundsSW = this.map.getBounds().getSouthWest()
                let center = this.map.getCenter()
                this.$emit('get-dealers-by-center', { bounds: { ne: { latitude: boundsNE.lat(), longitude: boundsNE.lng() }, sw: { latitude: boundsSW.lat(), longitude: boundsSW.lng() } }, center: { latitude: center.lat(), longitude: center.lng() } })
            },
            addMapMarker: function (self, dealer, index) {
                let LatLng = new google.maps.LatLng(dealer.lat, dealer.lng);
                self.markers[index] = new google.maps.Marker({
                    position: LatLng,
                    title: dealer.name,
                    icon: self.markerIconDealer,
                    //animation: google.maps.Animation.DROP,
                });
                if (index === self.activeDealer) {
                    self.markers[index].setIcon(self.markerIconDealerActive)
                }

                self.mapBounds.extend(LatLng)
                self.markers[index].setMap(self.map)
                self.markers[index].addListener('click', function () {

                    self.$emit('marker-selected', index);

                })

            }
        }
    }
</script>
