import 'url-polyfill'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from './App.vue'

Vue.use(VueI18n)

Vue.config.productionTip = false


const i18n = new VueI18n({
  locale: window.tx_dealersearch.dealersearch.locale || 'en_UK',
  messages: {
    en_UK: {
      global: {

      },
      breadcrumb: {
        home: 'BAVARIA YACHTS',
        service: 'Service',
        dealersearch: 'Dealersearch',
      },
      dealerlist: {
        title: 'Your next dealer is just a click away!',
        placeholder: 'Enter a location',
        moreButton: 'Show more dealers'
      },
      filter: {
        error: 'Please enter a location! ',
        filterText: 'What are you looking for?',
        othersText: 'Other dealers outside your country:',
        sailingyachts: 'Sailing Yachts',
        motorboats: 'Motor Boats',
        worldwide: 'BAVARIA YACHTS Worldwide'
      },
      googleApi: {
        title: 'Search'
      },
      consent: {
        text1: 'By accessing this service, you agree that your data will be transmitted to Google and that you have read the ',
        text2: '.',
        privacy: 'privacy',
        button: 'Enable Google Maps'
      }
    },
    de_DE: {
      global: {

      },
      breadcrumb: {
        home: 'BAVARIA YACHTS',
        service: 'Service',
        dealersearch: 'Händlersuche'
      },
      dealerlist: {
        title: 'Ihr nächster Händler ist nur einen Klick entfernt!',
        placeholder: 'Geben Sie einen Standort ein',
        moreButton: 'Mehr Ergebnisse anzeigen'
      },
      filter: {
        error: 'Bitte geben Sie einen Standort ein!',
        filterText: 'Wonach suchen Sie?',
        othersText: 'Weitere Händler außerhalb Ihres Landes:',
        sailingyachts: 'Segelyachten',
        motorboats: 'Motorboote',
        worldwide: 'BAVARIA YACHTS Worldwide'
      },
      googleApi: {
        title: 'Hier suchen'
      },
      consent: {
        text1: 'Mit dem Aufruf dieses Services erklären Sie sich einverstanden, dass Ihre Daten an Google übermittelt werden und bestätigen, dass Sie die ',
        text2: ' gelesen haben.',
        privacy: 'Datenschutzerklärung',
        button: 'Google Maps aktivieren'
      }
    }
  }
})

new Vue({
  i18n,
  render: h => h(App),
}).$mount('#app')
